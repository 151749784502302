import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import store from "./store";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    meta: { requiresAuth: true }, // Menandakan bahwa rute ini memerlukan autentikasi
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "/hp-users",
        name: "hp-users",
        component: () => import("./pages/master/HpUsers.vue"),
      },
      {
        path: "/hp-users/:id/territory",
        name: "hp-users-territory",
        component: () =>
          import("./components/master/hp-users/territory/Index.vue"),
      },
      {
        path: "/web-users",
        name: "web-users",
        component: () => import("./pages/master/WebUsers.vue"),
      },
      {
        path: "/reset-gps",
        name: "reset-gps",
        component: () => import("./pages/master/reset-gps/Index.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("./pages/master/Settings.vue"),
      },
      {
        path: "/territory",
        name: "territory",
        component: () => import("./pages/master/Territory.vue"),
      },
      {
        path: "/city",
        name: "city",
        component: () => import("./pages/master/City.vue"),
      },
      {
        path: "/outlet",
        name: "outlet",
        component: () => import("./pages/master/Outlet.vue"),
      },
      {
        path: "/outlet-type",
        name: "outlet-type",
        component: () => import("./pages/master/OutletType.vue"),
      },
      {
        path: "/distributor",
        name: "distributor",
        component: () => import("./pages/master/Distributor.vue"),
      },
      {
        path: "/product",
        name: "product",
        component: () => import("./pages/master/Product.vue"),
      },
      {
        path: "/beat",
        name: "beat",
        component: () => import("./pages/master/Beat.vue"),
      },
      {
        path: "/beat/:id/outlet",
        name: "beat-outlet",
        component: () => import("./components/master/beat/outlet/Index.vue"),
      },
      {
        path: "/beat/:id/user",
        name: "beat-user",
        component: () => import("./components/master/beat/user/Index.vue"),
      },
      {
        path: "/pjp",
        name: "pjp",
        component: () => import("./pages/master/PJP.vue"),
      },
      {
        path: "/visit",
        name: "visit",
        component: () => import("./pages/report/Visit.vue"),
      },
      {
        path: "/start-end",
        name: "start-end",
        component: () => import("./pages/report/StartEnd.vue"),
      },
      {
        path: "/attendances",
        name: "attendances",
        component: () => import("./pages/report/Attendances.vue"),
      },
      {
        path: "/outlet-sales",
        name: "outlet-sales",
        component: () => import("./pages/report/OutletSales.vue"),
      },
      {
        path: "/noo",
        name: "noo",
        component: () => import("./pages/report/Noo.vue"),
      },
      {
        path: "/outlet-orders",
        name: "outlet-orders",
        component: () => import("./pages/database/OutletOrders.vue"),
      },
      {
        path: "/stocks",
        name: "stocks",
        component: () => import("./pages/database/Stocks.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("./pages/database/Orders.vue"),
      },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("authToken");
  const pageName = to.name;

  if (to.name === "login" && isAuthenticated) {
    next({ name: "dashboard" });
  } else {
    // Mengecek apakah rute saat ini memiliki children
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      // Jika pengguna telah login, lanjutkan ke rute yang diminta
      if (isAuthenticated) {
        store.dispatch("auth/checkPermission", pageName);
        setTimeout(() => {
          if (localStorage.getItem("isPermission") == "false") {
            next({ name: "access" });
          } else {
            next();
          }
        }, 250);
      } else {
        // Jika pengguna belum login, redirect ke halaman login
        next({ name: "login" });
      }
    } else {
      // Jika rute tidak memiliki children, lanjutkan ke rute yang diminta
      next();
    }
  }
});

export default router;
